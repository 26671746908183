import { Controller } from "stimulus";


export default class CoinDiscussionController extends Controller {
  static targets = ["attribution", "discussion", "button"];

  connect() {
    // console.log("CoinDiscussionController connected");
    // console.log("Available targets:", this.targets);
    // console.log("Has attribution target?", this.hasAttributionTarget);
    // console.log("Has discussion target?", this.hasDiscussionTarget);
    this.originalButtonContent = this.buttonTarget.innerHTML;
  }

  async generateDiscussion(event) {
    // console.log("generateDiscussion called");
    const button = event.currentTarget;
    
    // Disable button and change text
    button.disabled = true;
    button.innerHTML = "Thinking...";

    if (!this.hasAttributionTarget) {
      console.error("Attribution target not found");
      return;
    }

    const attributionText = this.attributionTarget.innerText.trim();
    // console.log("Attribution text:", attributionText);

    try {
      const response = await fetch("/generate_discussion", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        },
        body: JSON.stringify({ attribution: attributionText })
      });

    //   console.log("Response status:", response.status);

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
    //   console.log("AI Discussion data received:", data);

      this.appendDiscussion(data.aiDiscussion);
    } catch (error) {
      console.error("Error generating discussion:", error);
    } finally {
      // Reset button state and restore original content
      button.disabled = false;
      button.innerHTML = this.originalButtonContent;
    }
  }

  appendDiscussion(aiDiscussion) {
    // console.log("Appending AI discussion:", aiDiscussion);
    this.discussionTarget.innerHTML += "<br>"
    this.discussionTarget.innerHTML += "************"
    this.discussionTarget.innerHTML += aiDiscussion;
    
    // Trigger the input event to update the hidden field
    $(this.discussionTarget).trigger('input');
  }
}
